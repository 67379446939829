import config from '../../config.json';
import React, { Component } from 'react';
import ConflictEventFilters from '../ConflictEventFilters/ConflictEventFilters';
import _ from 'underscore';
import axios from 'axios';
import './Filters.css';
import Moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { extendMoment } from 'moment-range';
import Map from '../Map/Map';
import { getTranslation } from '../util';
import { Slider, Select, Row, Button, Col, Icon, Menu, Dropdown, Switch, Spin } from 'antd';
import Profile from '../Profile/Profile';
const ReactHighcharts = require('react-highcharts');
const { Option } = Select;
const moment = extendMoment(Moment);

  /*
  * - Filters component
  * - Loads conflict data from S3
  */


  // Setup charts
  const chartConfig = {
    chart: {
            type: 'scatter',
            backgroundColor: 'transparent',
            height: 300,
            style: {
              fontFamily: 'Questrial'
            }
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        tooltip: {
          formatter: function() {
            return `${this.point.properties.name}`
          }
        },
        xAxis: {
            title: {
                enabled: true,
                text: 'text',
                style: {
                  color: '#ccc'
                }
            },
            lineColor: '#ccc',
            tickColor: '#ccc',
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: {
            gridLineColor: '#ccc',
            title: {
                text: 'text',
                style: {
                  color: '#ccc'
                }
            }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
            series: {
                 animation: false,
                 point: {
                    events: {
                        mouseOver: function () {
                          //console.log(this)
                          //showLocation(this.name)
                        },
                        mouseOut: function () {
                          //console.log(this)
                          //showLocationOff()
                        }
                    }
                }
             },
            scatter: {
                marker: {
                    radius: 6,
                    lineColor: '#F7661C',
                    lineWidth: 1,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
            }
        },
      series: [{
      name: 'Orgs',
      color: 'rgba(254, 142, 17, 0.5)',
      data: []
    }]
  }


  // Setup mapping date constants
  const start = moment(config.conflictData.start, 'YYYY-MM-DD')
  const end = moment(config.conflictData.end, 'YYYY-MM-DD')
  const range = moment.range(start, end)
  const arrayOfDates = Array.from(range.by('days'))
  const conflictDates = []
  _.each(arrayOfDates, function(date) {
    conflictDates.push(date.valueOf())
  })



  export default (class Filters extends Component {
    constructor(props) {
      super(props)
      this.state = {
        lng: 29,
        lat: -1,
        zoom: 6,
        min: conflictDates[0],
        max: conflictDates[conflictDates.length-1],
        conflicts: {
          type: "FeatureCollection",
          features: []
        },
        allConflicts: {
          type: "FeatureCollection",
          features: []
        },
        conflictFilters: ['all', ['!has', 'null']],
        orgFilter: 'reset',
        orgChartData: [],
        orgDetails: [],
        conflictDetails: [],
        conflictField: '',
        conflictValue: '',
        conflictFieldOpp: '!=',
        showOrgs: true,
        showConflicts: false,
        countryName: 'DR Congo',
        activeCountry: 'COD',
        chartConfig: chartConfig,
        'axis-x': 'established',
        'axis-y': 'assessmentScore',
        'axis-x-label': 'Established',
        'axis-y-label': 'Assessment Score',
        orgFilters: {},
        orgFiltersToggles: {},
        filteredOrgs: this.props.organisations,
        selectedOrg: null,
      }
      this.toggleConflicts = this.toggleConflicts.bind(this);
      this.toggleOrgs = this.toggleOrgs.bind(this);
      this.handleRange = this.handleRange.bind(this);
      this.setSelectedOrg = this.setSelectedOrg.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
      var _self = this
      if (this.state['axis-x'] !== prevState['axis-x'] ||
         this.state['axis-y'] !== prevState['axis-y']) {
        this.updateOrgChart()
      }
      if (this.state.conflictValue !== prevState.conflictValue) {
        this.filterConflicts().then((data)=> {
          this.setState({
            conflicts: data
          })
        })
      }
      if (this.props.mappingProject !== prevProps.mappingProject){
        this.queryData();
      }
    }

    queryData() {
      const _self = this;
      const geoIDs = [];
      const {startDate, endDate} = this.props.mappingProject.display_config.conflictData;

      // get the ids of the locations for which we want conflict event data
      const regionsCovered = this.props.mappingProject.regions_covered.features;

      for (let i = 0; i < regionsCovered.length; i += 1) {
        geoIDs.push(regionsCovered[i].id);
      }
      axios.all([
        axios.get(`/conflict-data.json?limit=50000&geo=${geoIDs.join(',')}&from=${startDate}&to=${endDate}`),
      ])
      .then(function(response) {
        const { conflicts } = _self.state
        _.each(response[0].data.results.features, function(feature) {
          feature.properties.timestamp = moment(feature.properties.datetime).valueOf()
          conflicts.features.push(feature)
        })

        // eslint-disable-next-line
        var newObj = new Object()
        newObj = Object.assign({}, conflicts)

        _self.setState({
          conflicts: newObj,
          allConflicts: newObj
        })
      })
    }

    filterConflicts() {
      var _self = this
      var geoJSON = {
        type: "FeatureCollection",
        features: []
      }

      return new Promise((resolve, reject) => {
      if (this.state.conflictFieldOpp === '!=') {
      _.each(this.state.allConflicts.features, function(feature) {
        if (parseFloat(feature.properties.timestamp) > _self.state.min &&
            parseFloat(feature.properties.timestamp) < _self.state.max
          ) {
          geoJSON.features.push(feature)
        }
      })
        resolve(geoJSON)
      }

      if (this.state.conflictFieldOpp === '==') {
        if (this.state.conflictValue === 'all') {
          _.each(this.state.allConflicts.features, function(feature) {
            if (feature.properties.timestamp > _self.state.min &&
                feature.properties.timestamp < _self.state.max
              ) {
              geoJSON.features.push(feature)
            }
          })
          resolve(geoJSON)
        } else {
          _.each(this.state.allConflicts.features, function(feature) {
            if (feature.properties.timestamp > _self.state.min &&
                feature.properties.timestamp < _self.state.max &&
                feature.properties[_self.state.conflictField] === _self.state.conflictValue
              ) {
              geoJSON.features.push(feature)
            }
          })
          resolve(geoJSON)
        }
      }

      if (this.state.conflictFieldOpp === 'in') {
        if (this.state.conflictValue === 'all') {
          _.each(this.state.allConflicts.features, function(feature) {
            if (feature.properties.timestamp > _self.state.min &&
                feature.properties.timestamp < _self.state.max
              ) {
              geoJSON.features.push(feature)
            }
          })
          resolve(geoJSON)
        } else {
          _.each(this.state.allConflicts.features, function(feature) {
            if (feature.properties.timestamp > _self.state.min &&
                feature.properties.timestamp < _self.state.max &&
                feature.properties[_self.state.conflictField].includes(_self.state.conflictValue)
              ) {
              geoJSON.features.push(feature)
            }
          })
          resolve(geoJSON)
        }
      }
    })
    }

    // resetMap(e) {
    //   this.setState({
    //     lng: 30,
    //     lat: 20,
    //     zoom: 2,
    //     orgFilter: 'none'
    //   })
    // }

    setConflictDetails(data) {
      this.setState({
        conflictDetails: data,
        orgDetails: null,
        selectedOrg: null,
      })
    }

    setOrgDetails(data) {
      var _self = this
      var chartData = []
      var config = this.state.chartConfig
      _.each(data, function(row) {
        // set these fields in state
        // -- whre to get options from - Config?
        var xValue = row.properties[_self.state['axis-x']]
        var yValue = row.properties[_self.state['axis-y']]
        if (xValue) {
          chartData.push({
            name: row.properties.name,
            properties: row.properties,
            x: parseFloat(xValue),
            y: parseFloat(yValue),
            point: row.geometry.coordinates
          })
        }
      })
      config.yAxis.title.text = _self.state['axis-y-label']
      config.xAxis.title.text = _self.state['axis-x-label']
      config.series[0].data = chartData
      this.setState({
        orgDetails: data,
        orgChartData: chartData,
        chartConfig: config,
        conflictDetails: null,
        selectedOrg: null,

      })
    }

    updateOrgChart() {
      var _self = this
      var data = this.state.orgDetails
      var chartData = []
      var config = this.state.chartConfig
      _.each(data, function(row) {
        // set these fields in state
        // -- whre to get options from - Config?
        var xValue = row.properties[_self.state['axis-x']]
        var yValue = row.properties[_self.state['axis-y']]
        if (xValue) {
          chartData.push({
            name: row.properties.name,
            properties: row.properties,
            x: _self.translateValues(xValue),
            y: _self.translateValues(yValue),
            point: row.geometry.coordinates
          })
        }
      })
      config.yAxis.title.text = _self.state['axis-y-label']
      config.xAxis.title.text = _self.state['axis-x-label']
      config.series[0].data = chartData
      this.setState({
        chartConfig: config
      })
    }

    translateValues(value) {
      if (config.translateValues[value]) {
        return config.translateValues[value]
      } else {
        return parseFloat(value)
      }
    }

    hideDetails(e) {
      e.preventDefault()
      this.setState({
        conflictDetails: null
      })
    }

    hideOrgDetails(e) {
      e.preventDefault()
      this.setState({
        orgDetails: null
      })
    }

    toggleOrgs() {
      this.setState({
        showOrgs: !this.state.showOrgs,
      })
    }

    toggleConflicts() {
      this.setState({
        showConflicts: !this.state.showConflicts,
      });
    }

    handleRange(value) {
      var min = conflictDates[value[0]]
      var max = conflictDates[value[1]-1]
      this.setState({
        min: min,
        max: max
      })
      this.filterConflicts().then((data)=> {
        this.setState({
          conflicts: data
        })
      })
    }


    handleInputChange(e) {
       const target = e.target;
       const value = target.type === 'checkbox' ? target.checked : target.value;
       const name = target.name;
       this.setState({
         [name]: value
       });
     }

    handleOrgFilter(e) {
      
      var value = e.target.getAttribute('data-value')
      var field = e.target.getAttribute('data-field')

      
      const newFilters = Object.assign({}, this.state.orgFilters);

      // Add a placeholder if this field doesn't alreadty exist
      if (!Object.keys(newFilters).includes(field)) {
        newFilters[field] = [];
      }

      for (let i = 0; i < Object.keys(newFilters).length; i++) {
        const fieldName = Object.keys(newFilters)[i];

        if (field === fieldName ) {
          if (value === 'reset' | value === 'all') {
            // Clear the filter, as requested
            newFilters[field] = [];
          } else {
            if (newFilters[field].includes(value)) {
              // Remove the value if it exists
              // This allow's toggling. IE clicking a filter that's "on" will turn it "off"
              const index = newFilters[field].indexOf(value);
              newFilters[field].splice(index, 1);
            } else {
              // Add it if it doesn't exist
              newFilters[field].push(value);
            }
          }
        }
      }
      
      this.setState({
        orgFilters: newFilters,
      })
    }

    handleToggleOrgFilter(e) {
      const { orgFiltersToggles } = this.state;
      const field = e.target.getAttribute('data-field')
      if (!orgFiltersToggles[field]) {
        orgFiltersToggles[field] = true;
      } else {
        orgFiltersToggles[field] = false;
      }
      this.setState({orgFiltersToggles});
    }

    handleConflictFilter(e) {
      var value = e.target.getAttribute('data-value')
      var field = e.target.getAttribute('data-field')
      if (field === 'actors') {
        this.setState({
          conflictField: field,
          conflictValue: value,
          conflictFieldOpp: 'in'
        })
      } else {
        this.setState({
          conflictField: field,
          conflictValue: value,
          conflictFieldOpp: '=='
        })
      }
    }

    handleGraphField(value) {
      var option = value.domEvent.target.getAttribute('data-value')
      var axis = value.domEvent.target.getAttribute('data-axis')
      var label = value.domEvent.target.getAttribute('data-label')
      //chartConfig.chart[`${axis}Axis`].title.text = option
      this.setState({
        [`axis-${axis}`]: option,
        [`axis-${axis}-label`]: label
      })
    }

    handleOrgGraphOptions(axis) {
      const menu = (
      <Menu>
        { config.graphFields.COD.map((option, i) =>
          <Menu.Item
           onClick={ this.handleGraphField.bind(this) }
           key={ `org-filter-${i}`}
           >
            <button className="axis-button" data-label={ option.name }data-axis={ axis } data-value={ option.value }>{ option.name }</button>
          </Menu.Item>
        )}
      </Menu>
    )
     return menu
    }

    setOrgsFilterOptionsFocus(data) {
      this.setState({
        orgsFilterOptionsFocus: data
      })
    }

    setConflictFilterOptions(data) {
      this.setState({
        conflictFilterOptions: data
      })
    }

    setConflictSubFilterOptions(data) {
      this.setState({
        conflictSubFilterOptions: data
      })
    }

    setConflictActorFilterOptions(data) {
      this.setState({
        conflictActorFilterOptions: data
      })
    }

    translateTerm(term) {
      if (config.translateTerms[term]) {
        return config.translateTerms[term]
      } else {
        return term
      }
    }

    setSelectedOrg(selectedOrg) {
      this.setState({selectedOrg})
    }

    render() {
      const {
        mappingProject,
        organisations,
        filters,
        locale,
      } = this.props;
      if (mappingProject && this.state.allConflicts.features.length > 0) {
        // build the organsation filters
        const orgFilters = [];
        for (let i = 0; i < filters.length; i += 1) {
          const filter = filters[i];
          const stateFilter = this.state.orgFilters[filter.fieldName] !== undefined ? this.state.orgFilters
          [filter.fieldName] : [];
          const toggleButtonLabel = !this.state.orgFiltersToggles[filter.fieldName] ? <FormattedMessage id="filters.show_options" defaultMessage="Show options" /> : <FormattedMessage id="filters.show_options" defaultMessage="Hide options" />;
          const menuClassname = !this.state.orgFiltersToggles[filter.fieldName] ? 'pi-menu-content hidden' : 'pi-menu-content';

          orgFilters.push(
            <div className="pi-menu">
              <div className="pi-menu-header">
                {getTranslation(filter.labels, locale)}
                <button
                  className="pi-menu-toggle"
                  onClick={ this.handleToggleOrgFilter.bind(this) }
                  data-field={filter.fieldName}
                >
                  {toggleButtonLabel}
                </button>
              </div>
              <div className={menuClassname}>
                <button
                  onClick={ this.handleOrgFilter.bind(this) }
                  data-field={filter.fieldName}
                  data-value="reset"
                  className={ stateFilter.length === 0 ? 'active' : undefined }
                  key="all">
                  All
                </button>
                { filter.values.map((v, i) =>
                  <button
                  onClick={ this.handleOrgFilter.bind(this) }
                  data-field={ filter.fieldName }
                  data-value={ v }
                  className={ stateFilter.includes(v) ? 'active' : undefined }
                  key={i}>
                    {v} ({filter.collections[v].length})
                  </button>
                )}
              </div>
            </div>
          );
        }

        return (
          <>
          <div className="filters">
            <div className="content">
              <div className="orgs">
                <div className="filter-header">
                  <button><FormattedMessage id="filters.organisations" defaultMessage="Organisations" /></button>
                </div>
                <div className="filter-content">
                  <Row className="org-switch">
                    <Col span={21}>
                      <FormattedMessage id="filters.show_organisations" defaultMessage="Show organisations" />
                    </Col>
                    <Col span={3}>
                      <Switch
                        size="small"
                        onChange={ this.toggleOrgs }
                        checked={ this.state.showOrgs }
                      />
                    </Col>
                  </Row>
                  {orgFilters}

                  { this.state.orgsFilterOptionsFocus && this.state.orgsFilterOptionsFocus.length > 0 &&
                    <div className="pi-menu">
                      <div className="pi-menu-header">
                        <FormattedMessage id="filters.primary_focus" defaultMessage="Primary Focus" />
                      </div>
                      <div className="pi-menu-content">
                        <button
                          onClick={ this.handleOrgFilter.bind(this) }
                          data-field="all"
                          data-value="reset"
                          className={ this.state.orgFilter === "all" ? 'active' : undefined }
                          key="all"
                        >
                          <FormattedMessage id="filters.all" defaultMessage="All" />
                        </button>
                        { this.state.orgsFilterOptionsFocus && this.state.orgsFilterOptionsFocus.map((option, i) =>
                          <button
                            onClick={ this.handleOrgFilter.bind(this) }
                            data-field={ option.field }
                            data-value={ option.name }
                            className={ this.state.orgFilter === option.name ? 'active' : undefined }
                            key={i}
                          >
                            { this.translateTerm(option.name) } ({option.count})
                          </button>
                        )}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <ConflictEventFilters
                mappingProject={mappingProject}
                toggleConflicts={this.toggleConflicts}
                showConflicts={this.state.showConflicts}
                conflicts={this.state.conflicts}
                handleRange={this.handleRange}
              />
            </div>  
          </div>


          { this.state.conflictDetails && this.state.conflictDetails.length > 0 &&
            <div className="conflict-details info-panel">
              <div className="btn-close">
                <button onClick={ this.hideDetails.bind(this) }>
                  x
                </button>
              </div>
              <div className="content">
                { this.state.conflictDetails.map((conflict, i) =>
                  <div className="item" key={ i }>{ conflict.properties.description }</div>
                )}
              </div>
            </div>
          }

        { this.state.orgDetails && this.state.orgDetails.length > 0 &&
          <div className="org-details info-panel">
            <div className="btn-close">
              <button onClick={ this.hideOrgDetails.bind(this) }>
                x
              </button>
            </div>
            <div className="content">
              <p>
                <FormattedMessage id="filters.org_list_intro" defaultMessage="The following organisations work in this location. Click an organisation to view the full profile." />
              </p>
              { this.state.orgDetails && this.state.orgDetails.map((org, i) =>
                <div className="org-list-item" key={ `org-item-${i}` } onClick={ () => (this.setSelectedOrg(org)) }>
                  <div className="org-list-item-label">
                    {org.properties[mappingProject.display_config.org_listing_config.title_field]}
                  </div>
                  <div className="org-list-item-description">

                    {org.properties[mappingProject.display_config.org_listing_config.description_field].length > 200 ? org.properties[mappingProject.display_config.org_listing_config.description_field].slice(0, 197) + '...' : org.properties[mappingProject.display_config.org_listing_config.description_field]}
                  </div>                
                </div>
              )}
            </div>
          </div>
        }

        <Map
          mappingProject={mappingProject}
          lng={ this.state.lng }
          lat={ this.state.lat }
          min={ this.state.min }
          max={ this.state.max }
          zoom={ this.state.zoom }
          showAll={ this.state.showAll }
          organisations={organisations}
          orgsFilterOptionsFocus={ this.setOrgsFilterOptionsFocus.bind(this) }
          orgFilters={ this.state.orgFilters }
          filters={ this.props.filters }
          conflictDetails={ this.setConflictDetails.bind(this) }
          conflictFilterOptions={ this.setConflictFilterOptions.bind(this) }
          conflictSubFilterOptions={ this.setConflictSubFilterOptions.bind(this) }
          conflictActorFilterOptions={  this.setConflictActorFilterOptions.bind(this) }
          conflicts={ this.state.conflicts }
          orgDetails={ this.setOrgDetails.bind(this) }
          conflictFilters={ this.state.conflictFilters }
          orgFilter={ this.state.orgFilter }
          orgField={ this.state.orgField }
          showOrgs={ this.state.showOrgs }
          showConflicts={ this.state.showConflicts }
          showGroups={true}
        />

        { this.state.selectedOrg && 
          <Profile
            org={this.state.selectedOrg}
            setSelectedOrg={this.setSelectedOrg}
            locale={locale}
            config={mappingProject.display_config.profile}
          />
        }
        </>
      )
    } else {
      return (
        <div className="loading">
          <div className="loader"><Spin tip="Loading..." size="large"/></div>
        </div>
      )
    }
  }
})
