export const getTranslation = (obj, locale) => {
  // Assumes the `obj` is something like {'fr': 'string', 'en': 'string'}.
  // Returns the value for the requested locale

  if( obj[locale] ) {
    return obj[locale];
  } else {
    // it doesn't exist, so try and return english
    if( obj.en ) {
      return obj.en;
    } else {
      // otherwise, sort the keys alphabetically (for consistency) and return the first.
      const keys = Object.keys(obj);
      keys.sort();
      return obj[keys[0]];
    }
  }
}