import config from '../../config.json';
import React, { Component } from 'react';
import Graph from '../Graph/Graph';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Slider, Select, Row, Button, Col, Icon, Menu, Dropdown, Switch, Spin } from 'antd';
import _ from 'underscore';
import './ConflictEventFilters.css';


/*
* ConflictEventFilters component
*/

class ConflictEventFilters extends Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = props.mappingProject.display_config.conflictData;
    const start = moment(startDate, 'YYYY-MM-DD')
    const end = moment(endDate, 'YYYY-MM-DD')
    const range = moment.range(start, end)
    const arrayOfDates = Array.from(range.by('days'))
    const conflictDates = []
    _.each(arrayOfDates, function(date) {
      conflictDates.push(date.valueOf())
    })

    this.state = {
      conflicts: [],
      fatalities: 0,
      actors: [],
      conflictDates,
    }
    this.tipFormat = this.tipFormat.bind(this);
  }

  componentDidMount() {
    this.filterConflictData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.conflicts.features.length !== prevProps.conflicts.features.length) {
      this.filterConflictData()
    }
  }

  getDates(startDate, endDate) {
    var dates = {};
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
      var date = currDate.clone().toDate()
      dates[parseFloat(moment(date).format('x'))] = { count: 0, fatalities: 0, date: parseFloat(moment(date).format('x')) }
    }
    return dates;
  }

  tipFormat(value) {
    var ts = this.state.conflictDates[value-1]
    if (!(ts)) {
      ts = this.state.conflictDates[value]
    }
    var date = moment(ts).format('MMMM DD, YYYY')
    return(date)
  }

  filterConflictData() {
    var _self = this;
    var array = [];
    var fatalities = 0;
    const { startDate, endDate } = _self.props.mappingProject.display_config.conflictData;

    const incidents = _self.getDates(moment(startDate), moment(endDate));

    _.each(this.props.conflicts.features, function(feature, index) {
      // if there is a single filter
          array.push(feature);

          // INCIDENTS
          const date = feature.properties.timestamp;
          if (incidents[date] && incidents[date].count) {
            incidents[date].count = incidents[date].count + 1
            incidents[date].fatalities = parseInt(incidents[date].fatalities) + parseInt(feature.properties.fatalities)
          } else {
            incidents[date] = {
              date: date,
              count: 1,
              fatalities: parseInt(feature.properties.fatalities)
            }
          }

          // ACTORS
          // const featureActors = feature.properties['actors'];
          // for (let i = 0; i < featureActors.length; i++) {
          //   const actor = featureActors[i];
          //   if (actors[actor]) {
          //     actors[actor].count = actors[actor].count + 1
          //   } else {
          //     actors[actor] = {
          //       name: actor,
          //       count: 1
          //     }
          //   }  
          // }
          fatalities = parseInt(fatalities) + parseInt(feature.properties.fatalities)
    })
    // var actorsArray = []
    // _.each(actors, function(actor) {
    //   actorsArray.push(actor)
    // })
    // actorsArray = _.sortBy(actorsArray, 'count').reverse()


    var incidentsArray = []
    _.each(incidents, function(incident) {
      incidentsArray.push([parseFloat(incident.date), incident.count])
    })
    incidentsArray = _.sortBy(incidentsArray, 'date')

    var fatalitiesArray = []
    _.each(incidents, function(incident) {
      fatalitiesArray.push([parseFloat(incident.date), parseInt(incident.fatalities)])
    })
    fatalitiesArray = _.sortBy(fatalitiesArray, 'date')

    this.setState({
      conflicts: array,
      fatalities: fatalities,
      // actors: actorsArray,
      incidents: incidentsArray,
      fatalityCounts: fatalitiesArray,
      profileLoaded: true
    })
  }

  handleBorderWidth(count) {
    var percent = count / this.state.conflicts.length * 100
    return percent + '%'
  }

  render() {
    
    
    if (this.state.profileLoaded) {
      return(
        <>
        <div className="conflict-event-filters">
          <div className="filter-header conflict-header">
              <button><FormattedMessage id="profile.conflict_events" defaultMessage="Conflict events" /></button>
          </div>
          <div className="filter-content">
           <Row className="conflict-switch">
            <Col span={21}>
              <FormattedMessage id="profile.show_conflict_events" defaultMessage="Show conflict events" />
            </Col>
            <Col span={3}>
           <Switch
             size="small"
             onChange={this.props.toggleConflicts}
             checked={ this.props.showConflicts }
           />
           </Col>
           </Row>

           <FormattedMessage id="profile.date_range" defaultMessage="Date range" />
           <Slider
             range
             onChange={ this.props.handleRange }
             min={ 0 }
             tipFormatter={ this.tipFormat }
             max={ this.state.conflictDates.length }
             defaultValue={[0, this.state.conflictDates.length]}
            />
           </div>
            { this.state.incidents &&
            <div className="incident-chart pi-chart">
              <Graph
                data1={ this.state.incidents }
                label={this.props.intl.formatMessage(
                  {
                    id: 'profile.incidents',
                    defaultMessage: 'Incidents',
                  }
                )}
              />
            </div>
            }
            <div className="incident-count"><FormattedMessage id="profile.incident_count" defaultMessage="{ count } incidents" values={{ count: this.state.conflicts.length }} /> <br/> <FormattedMessage id="profile.source_acled" defaultMessage="Source: ACLED" /></div>
            { this.state.incidents &&
            <div className="incident-chart pi-chart">
              <Graph
                data1={ this.state.fatalityCounts }
                label={this.props.intl.formatMessage(
                  {
                    id: 'profile.fatalities',
                    defaultMessage: 'Fatalites',
                  }
                )}
              />
            </div>
            }
            <div className="fatalities-count"><FormattedMessage id="profile.incident_count" defaultMessage="{ count } fatalities" values={{ count: this.state.fatalities }} /> <br/> <FormattedMessage id="profile.source_acled" defaultMessage="Source: ACLED" /></div>
            
        </div>
        </>
      )
  } else {
    return null
  }
  }

}

export default injectIntl(ConflictEventFilters);