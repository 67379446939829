import config from '../../config.json'
import React, { Component } from 'react'
import ReactMapboxGl, { Layer, Source, ZoomControl, ScaleControl, /*Popup*/  } from "react-mapbox-gl";
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import bbox from '@turf/bbox';

import './Map.css';
import _ from 'underscore'
const Map = ReactMapboxGl({
  injectCSS: true,
  accessToken: "pk.eyJ1IjoicGVhY2VpbnNpZ2h0IiwiYSI6ImNqbm9nMHFvNjA1MnQzdm0xaWNxM3l5d3YifQ.pXF7u303bNopP7uyVBK8tA",
  scrollZoom: false,
});


/*
* - Map component
* - Loads orgs from S3
*/

export default (class peacemap extends Component {
  constructor(props) {
    super(props);
    const bounds = bbox(props.mappingProject.regions_covered);

    this.state = {
      fitBounds: [[bounds[0], bounds[1]], [bounds[2], bounds[3]]],
      orgsList: props.organisations,
      orgs: props.organisations,
    }
  }

  componentDidMount() {
    if (this.props.conflicts) {
      this.getConflictFilterOptions('event-type', 'conflictFilterOptions')
      this.getConflictFilterOptions('sub-event-type', 'conflictSubFilterOptions')
      this.getConflictFilterOptions('actors', 'conflictActorFilterOptions')
    }
  }

  onMapLoad(map) {
    this.setState({ map: map })
  }

  componentDidUpdate(prevProps, prevState) {
    var _self = this
    if (this.props.orgFilters !== prevProps.orgFilters) {
      this.filterOrgData()
    }
    if (!(prevProps.activeCountry) && this.props.activeCountry && !(this.props.showAll)) {
      setTimeout(function(){
        _self.filterOrgData()
      }, 2000)
    }
    if (this.props.conflicts.features.length !== prevProps.conflicts.features.length) {
        //this.filterOrgData()
      this.getConflictFilterOptions('event-type', 'conflictFilterOptions')
      this.getConflictFilterOptions('sub-event-type', 'conflictSubFilterOptions')
      this.getConflictFilterOptions('actors', 'conflictActorFilterOptions')
    }
  }


  filterOrgData() {
    const { orgFilters, filters } = this.props;

    var _self = this
    var orgs = {
      type: "FeatureCollection",
      features: []
    }

    const fields = Object.keys(orgFilters);

    if (fields.length === 0) {
      // We don't have any filters, show everything
      orgs = this.state.orgsList;
    } else {
      let includedOrgIDs = [];
      const filtersMap = {};
      let isFiltered = false;

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        filtersMap[filter.fieldName] = filter;
      }

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const values = orgFilters[field];

        // If there's nothing in this array, no filters are selected, so don't need to do anything
        if (values.length > 0) {
          isFiltered = true;
          const fieldIDs = [];
          for (let x = 0; x < values.length; x++) {
            const value = values[x];
            const valueIDs = filtersMap[field].collections[value];
            for (let z = 0; z < valueIDs.length; z++) {
              const id = valueIDs[z];
              if (!fieldIDs.includes(id)) {
                fieldIDs.push(id);
              }
            }
          }
          // Now we have all the vaalues of the orgs we want, cross-reference with the includedIDs and make sure the lists match
          if (includedOrgIDs.length === 0) {
            // We have nothing in the includedIDs yet, so we can use this list
            includedOrgIDs = fieldIDs;
          } else {

            // Add remove ids from includedIDs that don't appear in this list
            const newIncludedOrgIDs = []
            for (let x = 0; x < includedOrgIDs.length; x++) {
              const id = includedOrgIDs[x]; 
              if (fieldIDs.includes(id)) {
                newIncludedOrgIDs.push(id);
              }
            }
            includedOrgIDs = newIncludedOrgIDs;
          }
          
        }
      }

      if (!isFiltered) {
        // If we've not filtered anything, give the full list
        orgs = this.state.orgsList;
      } else {
        for (let i = 0; i < this.state.orgsList.features.length; i++) {
          const feature = this.state.orgsList.features[i];
          if (includedOrgIDs.includes(feature.id)) {
            orgs.features.push(feature);
          }
        }
      }
      
    }


    // if (this.props.orgFilter === 'none' || this.props.showAll) {
    //   orgs = this.state.orgsList
    // } else if (this.props.orgFilter !== 'reset') {
    //     _.each(this.state.orgsList.features, function(feature) {
    //       if (feature.properties.country === _self.props.activeCountry &&
    //           feature.properties[_self.props.orgField] === _self.props.orgFilter) {
    //         orgs.features.push(feature)
    //       }
    //     })
    // } else {
    //   _.each(this.state.orgsList.features, function(feature) {
    //     if (feature.properties.country === _self.props.activeCountry) {
    //       orgs.features.push(feature)
    //     }
    //   })
    // }

    this.setState({
      orgs
    })
    this.getOrgFilterOptions()
  }

  getOrgFilterOptions() {
    var _self = this
    var type
    if (_self.props.activeCountry === 'COD') {
      type = 'focus'
    } else {
      type = 'type'
    }
    this.setState({
      orgFilterField: type
    })
    var propertiesObj = {
      [type]: {}
    }
    _.each(this.state.orgsList.features, function(row, i) {
      if (row.properties.country === _self.props.activeCountry) {
        //console.log(row.properties)
      if (row.properties[type]) {
        if (propertiesObj[type][row.properties[type]]) {
          propertiesObj[type][row.properties[type]].count = propertiesObj[type][row.properties[type]].count + 1
        } else {
          propertiesObj[type][row.properties[type]] = { name: row.properties[type], field: type, count: 1 }
        }
       }
      }
    })
    var propertiesArray = []
    _.each(propertiesObj[type], function(item) {
      propertiesArray.push({
        name: item.name,
        field: item.field,
        count: item.count
      })
    })
    propertiesArray = _.sortBy(propertiesArray, 'count').reverse()
    this.props.orgsFilterOptionsFocus(propertiesArray)
  }

  getConflictFilterOptions(type, propToUpdate) {
    var propertiesObj = {
      [type]: {}
    }
      _.each(this.props.conflicts.features, function(row, i) {
        //console.log(row.properties)
        if (row.properties[type]) {
          
          if (Array.isArray(row.properties[type])) {
            const items = row.properties[type];
            for (let i = 0; i < items.length; i++) {
              const item = items[i];
              if (propertiesObj[type][item]) {
                propertiesObj[type][item].count = propertiesObj[type][item].count + 1
              } else {
                propertiesObj[type][item] = { name: item, count: 1 }
              }
            }
          } else {
            const item = row.properties[type];
            if (propertiesObj[type][item]) {
              propertiesObj[type][item].count = propertiesObj[type][item].count + 1
            } else {
              propertiesObj[type][item] = { name: item, count: 1 }
            }
          }
        }
      })
    var propertiesArray = []
    _.each(propertiesObj[type], function(item) {
      propertiesArray.push({
        name: item.name,
        count: item.count
      })
    })
    propertiesArray = _.sortBy(propertiesArray, 'count').reverse()
    this.props[propToUpdate](propertiesArray)
  }

  onClickLayer(e) {
    var features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-conflicts'] })
    this.props.conflictDetails(features)
  }

  onOrgClick(e) {
    var _self = this
    var cluster = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs'] })
    var point_count = cluster[0].properties.point_count
    if (point_count) {
      var clusterSource = this.state.map.getSource('source-orgs')
      clusterSource.getClusterLeaves(cluster[0].properties.cluster_id, point_count, 0, function(err, data) {
        _self.props.orgDetails(data)
      })
    } else {
      _self.props.orgDetails([cluster[0]])
    }
  }

  onOrgCountryClick(e) {
    var _self = this
    var cluster = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs-country'] })
    var point_count = cluster[0].properties.point_count
    if (point_count) {
      var clusterSource = this.state.map.getSource('source-orgs')
      clusterSource.getClusterLeaves(cluster[0].properties.cluster_id, point_count, 0, function(err, data) {
        _self.props.orgDetails(data)
      })
    } else {
      _self.props.orgDetails([cluster[0]])
    }
  }


  resetOrgData() {
    var orgs = this.state.orgsList
    this.setState({
      orgs: orgs
    })
  }

  onMouseEnter(e) {
    let features = []
    if (this.state.map.getLayer('layer-orgs-country')) {
      features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs-country'] })
    }
    if (this.state.map.getLayer('layer-orgs')) {
      features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs'] })
    }
    if (this.state.map.getLayer('layer-conflicts')) {
      features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-conflicts'] })
    }
    if (features[0]) {
      this.state.map.getCanvas().style.cursor = 'pointer'
    }
  }

  onMouseLeave(e) {
    this.state.map.getCanvas().style.cursor = ''
  }

  render() {
    if (this.state.orgsList && this.state.orgsList.features.length > 0) {
    return (
     <>
     <Map
     className={ 'pi-map' }
     fitBounds={this.state.fitBounds}
     fitBoundsOptions={{padding: 100}}
     onStyleLoad={this.onMapLoad.bind(this)}
     // eslint-disable-next-line
     style={ 'mapbox://styles/peaceinsight/cju8nk5a62cbt1fnyilzhtgqd' }
     containerStyle={{
          height: '100%',
          width: '100%'
        }}
     >
     <Source
      id="source-orgs"
      geoJsonSource={{
        type: "geojson",
        data: this.state.orgs,
        cluster: true,
        clusterRadius: 20
      }}
      />
      { this.props.conflicts &&
      <Source
       id="source-conflicts"
       geoJsonSource={{
         type: "geojson",
         data: this.props.conflicts
       }}
       />
     }
     { this.props.conflicts &&
       this.props.showConflicts &&
     <Layer
       id="layer-conflicts"
       sourceId="source-conflicts"
       type="circle"
       onMouseEnter={ this.onMouseEnter.bind(this) }
       onMouseLeave={ this.onMouseLeave.bind(this) }
       onClick={ this.onClickLayer.bind(this) }
       paint={{
         "circle-color": "#ED1B23",
         'circle-opacity': 0.1,
         'circle-stroke-color': "#ED1B23",
         'circle-stroke-width': 1,
         'circle-stroke-opacity': 0.3,
         'circle-blur': 0,
         'circle-radius': {
           'property': 'fatalities',
           'stops': [
             [0, 3],
             [20, 6],
             [100, 10]
           ]
         }
       }}
     />
     }
    { this.props.showOrgs &&
      <Layer
        id="layer-orgs"
        sourceId="source-orgs"
        type="circle"
        onMouseEnter={ this.onMouseEnter.bind(this) }
        onMouseLeave={ this.onMouseLeave.bind(this) }
        onClick={ this.onOrgClick.bind(this) }
        paint={{
          "circle-color": "#fff",
          'circle-opacity': 1,
          'circle-stroke-color': '#F98B11',
          'circle-stroke-width': 3,
          'circle-radius': [
            "step",
            ["get", "point_count"],
            15,
            100,
            15
          ]
        }}
      />
    }
    
    
    { this.props.showOrgs &&
        <Layer
          id="layer-orgs-count"
          sourceId="source-orgs"
          type="symbol"
          layout={{
            "text-field": "{point_count_abbreviated}",
            "text-allow-overlap": true,
            "text-size": 11
          }}
          paint={{
            "text-color": "#ED1B23"
          }}
        />
      }
      <div className="zoom-cntrl">
        <ZoomControl />
      </div>
      <ScaleControl />
      </Map>
     </>
   )
   } else {
     return null
   }

}
})
