import React, { Component } from 'react';
import axios from 'axios';
import config from './config.json';
import Filters from './components/Filters/Filters';
import { getTranslation } from './components/util';
import { IntlProvider } from 'react-intl';
import enLocaleData from './locales/en.json';
import frLocaleData from './locales/fr.json';
import 'antd/dist/antd.css';
import './App.css';


class App extends Component {

  constructor(props) {
    super(props)

    const appLocaleData = props.locale === 'fr' ? frLocaleData : enLocaleData;

    this.state = {
      mappingProject: null,
      organisations: null,
      filters: null,
      messages: appLocaleData,
    };
    this.getMappingProject();
    this.handleChangeLocale = this.handleChangeLocale.bind(this);

  }

  handleChangeLocale(event) {
    const locale = event.target.value;
    let location = window.location.href;
    location = location.replace('?language=fr', ''); // TODO: replace the language query more robustly
    location = location.replace('?language=en', '');
    window.location.href = `${location}?language=${locale}`;
  }

  getMappingProject() {
    const _self = this

    axios.get(config.mappingProjectURI)
      .then(function(response) {
        const organisations = {
          type: "FeatureCollection",
          features: []
        }
        for (let i = 0; i < response.data.data.length; i += 1) {
          const organisation = response.data.data[i];

          if (!organisation.versions[0].data[response.data.display_config.coords_fieldname].includes('°')){
            const coords = organisation.versions[0].data[response.data.display_config.coords_fieldname].split(',');
            let data = null;

            // Get the version of the data for the current locale
            for (let x = 0; x < organisation.versions.length; x += 1) {
              const version = organisation.versions[x];
              if (version.language === _self.props.locale) {
                data = version.data;
              }
            }
            if (!data) data = organisation.versions[organisation.versions.length - 1].data; // If we don't end up with any data, just take the last

            organisations.features.push({
              id: organisation.data_id,
              type: "Feature",
              geometry: {
                type: 'Point',
                coordinates: [coords[1], coords[0]],
              },
              properties: data,
            });
          }
        }

        const filters = response.data.display_config.filters.map(a => ({...a}));
        for (let i = 0; i < filters.length; i += 1) {
          const filter = filters[i];
          if (filter.type === 'list') {

            // Iterate over all the possible values
            const values = [];
            const collections = {};
            for (let x = 0; x < response.data.data.length; x++) {
              const organisation = response.data.data[x];
              let orgData = null;
              for (let x = 0; x < organisation.versions.length; x += 1) {
                const version = organisation.versions[x];
                if (version.language === _self.props.locale) {
                  orgData = version.data;
                }
              }

              if (!orgData) orgData = organisation.versions[organisation.versions.length - 1].data; // If we don't end up with any data, just take the last
              
              const orgValues = orgData[filter.fieldName].split(';')

              for (let y = 0; y < orgValues.length; y++) {
                const v = orgValues[y] === '' ? 'Unknown' : orgValues[y];
                if (!values.includes(v)) {
                  values.push(v);
                  collections[v] = [];
                }
                collections[v].push(organisation.data_id);
              }  

            }
            values.sort();
            filter['values'] = values;
            filter['collections'] = collections;
          }

        }

        _self.setState({
          mappingProject: response.data,
          filters,
          organisations,
        })
      })
  }

  render() {
    const {
      mappingProject,
      organisations,
      filters,
      messages,
    } = this.state;

    const {
      locale,
      title,
      summary,
      availableLocales,
    } = this.props;

    // Build the language switcher tool
    const languageOptions = availableLocales.map((x) => <option value={x[0]}>{x[1]}</option>);

    // only show language switcher if there are 2+ languages available
    const headerTools = languageOptions.length < 2 ? '' : (
      <div class="header-tools">
        <strong>Change language: </strong>
          <select onChange={this.handleChangeLocale} value={locale}>
            {languageOptions}
          </select>
      </div>
    );

    return (
      <IntlProvider defaultLocale="en" locale={locale} key={locale} messages={messages}>
        <div class="container">
          <header>
            <div class="header-block">
              <h1>{title}</h1>
              <div class="summary">{summary}</div>
            </div>
            {headerTools}
            
          </header>
          <div class="app">
            <Filters
              mappingProject={mappingProject}
              organisations={organisations}
              filters={filters}
              locale={locale}
            />
          </div>
          
        </div>
      </IntlProvider>
    )
  }
}

export default App
