import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getTranslation } from '../util';
import './Profile.css';


const createProfileElement = (organisation, elementConfig, locale) => {
  let content;
  if (elementConfig.type === 'field') {
    // 'field' here means that we want to pull the appropriate value from organisation.properties
    if (elementConfig.contentType === 'list') {
      // Where the contentType is 'list' we assume the content attribute is a string with list items
      // sepeated by ';'. We want them formatted nicer
      const items = [];
      const items_list = organisation.properties[elementConfig.content].split(';');

      // We might have duplicate items in the list, so we'll remove dupes here
      for (let i = 0; i < items_list.length; i++) {
        const x = items_list[i];
        if (!items.includes(x)) {
          items.push(x);
        }
      }
      content = organisation.properties[elementConfig.content] ? items.map(x => `<div>${x}</div>`).join('') : "<em>No data</em>";
    } else {
      // If the value doesn't exist, default to "No data"
      content = organisation.properties[elementConfig.content] ? organisation.properties[elementConfig.content] : "<em>No data</em>";
    }
  } else {
    // If we're not getting a field, assume we are being passed an object with available translations for the string we want to show
    // in the format: {'iso2 code': 'string to show', ...} eg: {'fr': 'french stuff', 'en': 'english version'}
    content = getTranslation(elementConfig.content, locale)
  }
  return `<${elementConfig.element}>${content}</${elementConfig.element}>`;
}


const createProfileTable = (organisation, elementConfig, locale) => {
  // Creates a table element
  const rows = [];
  for (let i = 0; i < elementConfig.rows.length; i += 1) {
    const row = elementConfig.rows[i];
    const cells = [];
    for (let x = 0; x < row.length; x += 1) {
      const cell = row[x];
      cells.push(createProfileElement(organisation, cell, locale));
    }
    rows.push(`<tr>${cells.join('')}</tr>`);
  }

  return `<table class="profile-table">${rows.join('')}</table>`;
}


const createProfileHtml = (organisation, config, locale) => {
  // Builds the html for the organisation, based of mappingProject.display_config.profile
  // Config is made up of a json list describing html elements (type, content, etc)
  // See Peace Insight django code for example of how this is structured
  // TODO: Relying on dangerouslySetInnerHTML is prob a bad idea...

  let html = '';
  for (let i = 0; i < config.length; i += 1) {
    const item = config[i];
    if (item.element === 'table') {
      html += createProfileTable(organisation, item, locale);
    } else {
      html += createProfileElement(organisation, item, locale);
    }
  }

  return {__html: html};
}


/*
* Profile component
*/

const Profile = ({org, setSelectedOrg, config, locale}) => {
  return (
    <div className='profile info-panel'>
      <div className="btn-close">
        <button onClick={ () => {setSelectedOrg(null)} }>
          x
        </button>
      </div>
      <div dangerouslySetInnerHTML={createProfileHtml(org, config, locale)} />
    </div>
  );
};

export default injectIntl(Profile);
