import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Get the language, as set by a quaery param
let locale = 'en';

const search = window.location.search;
const params = new URLSearchParams(search);

const requestedLocale = params.get('language');
let title = 'Ituri mapping';
let summary = '';
if (requestedLocale) {
  locale = requestedLocale;
  if (locale === 'fr') {
    title = 'Ituri mapping';
    summary = '';
  }
}

// TODO: implement this
const availableLocales = [
  ['en', 'English'],
  // ['fr', 'Français'],
]

ReactDOM.render(
  <App
    title={title}
    summary={summary}
    locale={locale}
    availableLocales={availableLocales}
  />, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
